const roles = [
  {
    code: 'd',
    name: 'Jefe de área',
    fullName: 'Jefe de área',
    purpose: 'autorización de accesos',
    fullPurpose: 'Autorización de las solicitudes de mi equipo',
    functions: ['Autorizar solicitudes de acceso', 'Nombrar mi área a cargo '],
    avatar: '',
    icon: 'mdi-account-hard-hat'
  },
  {
    code: 'e',
    name: 'Monitor',
    fullName: 'Monitor',
    purpose: 'revisión de requisitos de acceso',
    fullPurpose: 'Revisión de requisitos para el accesso',
    functions: [
      'Autorizar las constancias de cursos Covid para empleados',
      'Autorizar documentos de identificación, trabajo y seguridad social de los proveedores',
      'Dirigir a proveedores a enfermería para una revisión',
      'Cerrar proyectos de proveedores'
    ],
    avatar: '',
    icon: 'mdi-account-edit'
  },
  {
    code: 'f',
    name: 'Salud',
    fullName: 'Servicio médico',
    purpose: 'revisión sanitaria de las personas',
    fullPurpose: 'Revisión sanitaria del personal y los proveedores',
    functions: [
      'Permitir el acceso de acuerdo al estado de salud de las personas'
    ],
    avatar: '',
    icon: 'mdi-account-heart'
  },
  {
    code: 'g',
    name: 'Vigilancia',
    fullName: 'Vigilancia',
    purpose: 'Registro de los accesos a la planta',
    fullPurpose: 'Registra los ingresos y salidas de personal y proveedores',
    functions: [
      'Registrar los ingresos del personal y de los proveedores',
      'Registrar las salidas del personal y de los proveedores'
    ],
    avatar: '',
    icon: 'mdi-account-key'
  },
  {
    code: 'h',
    name: 'Jefe de Seguridad',
    fullName: 'Jefe de seguridad de la planta',
    purpose: 'vigilancia de las políticas',
    fullPurpose:
      'Vigilancia del nivel de ocupación de la planta y la asignación de roles',
    functions: [
      'Autorizar solicitudes de acceso a la planta',
      'Verificar la asignación de los Jefes Inmediatos a sus áreas',
      'Autorizar solicitudes de cambio de nombre de área',
      'Autorizar solicitudes de baja del personal y/o proveedores',
      'Autorizar solicitudes de cambio de planta del personal'
    ],
    avatar: '',
    icon: 'mdi-shield-home'
  }
]

export function roleByCode(code) {
  return roles.find(e => e.code === code)
}
export function codesRoles() {
  return roles.map(e => e.code)
}
export function getRoles() {
  return roles
}
