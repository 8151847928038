<template>
  <v-container :class="flexSpace" fluid>
    <v-alert
      max-width="674"
      border="right"
      type="info"
      elevation="2"
      v-if="!validEmail"
      rounded="xl"
      class="mx-auto mt-5 mb-5"
    >
      {{ $t('profile.supervisor.alert') }}
    </v-alert>

    <h1
      v-if="isValidSection('supervisor')"
      class="text-h6 text-md-h4 mt-6"
      :class="fontWeightLightMd"
    >
      {{ $t('profile.supervisor.title') }}
    </h1>
    <v-card width="374px" class="mx-auto" elevation="2" rounded="xl">
      <v-card-title v-if="emailEditable">
        <template>
          <v-sheet>
            <v-switch
              v-model="emailSwitch"
              hide-details
              label="Editar"
            ></v-switch>
          </v-sheet>
        </template>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validEmail">
          <v-text-field
            type="email"
            :label="$t('profile.supervisor.email')"
            prepend-icon="mdi-account-circle"
            :append-icon="validEmailIcon"
            v-model="email"
            required
            :clearable="emailEditable"
            :disabled="emailEditable && !emailSwitch"
            :rules="rules.email"
            @click="clear"
          />
        </v-form>
      </v-card-text>
    </v-card>

    <v-alert
      max-width="674"
      border="right"
      type="info"
      elevation="2"
      v-if="!isValidSection('ou')"
      rounded="xl"
      class="mx-auto mt-12"
    >
      {{ $t('profile.ou.alert') }}
    </v-alert>
    <orgs-cards :initOu="initOu" />

    <v-alert
      max-width="674"
      border="right"
      type="info"
      elevation="2"
      v-if="!isValidSection('roles')"
      rounded="xl"
      class="mx-auto mt-12"
    >
      {{ $t('profile.roles.alert') }}
    </v-alert>
    <roles-cards :initRoles="initRoles" />
  </v-container>
</template>

<script>
import rules from '@/micro/app/rules.js'
import { mapActions, mapGetters } from 'vuex'
import { getOrgProfile, initAssignments } from './fbApi.js'
import OrgsCards from '@/components/orgs/OrgsCards'
import RolesCards from '@/components/orgs/RolesCards'

export default {
  components: {
    OrgsCards,
    RolesCards
  },
  data: () => ({
    validEmail: true,
    emailSwitchState: false,
    rules,
    email: '',
    initOu: null,
    initRoles: null
  }),
  computed: {
    ...mapGetters('profile', [
      'isValidSection',
      'isValidProfileRequest',
      'draft'
    ]),
    emailEditable() {
      return this.validEmail
    },
    validEmailIcon() {
      return this.validEamil && !this.emailSwitch ? 'mdi-check' : ''
    },
    emailSwitch: {
      get() {
        return this.emailSwitchState
      },
      set(state) {
        this.emailSwitchState = state
      }
    },
    flexSpace() {
      return this.$vuetify.breakpoint.mdAndUpd ? '' : 'ma-0 pa-0'
    },
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    }
  },
  watch: {
    async validEmail(newValue) {
      this.updateValidSection({ section: 'supervisor', value: this.validEmail })
      if (this.validEmail) {
        this.saveDraft({ key: 'email', value: this.email })
        if (this.isValidProfileRequest) {
          this.setFieldsAccepted(newValue)
          const result = await initAssignments(
            this.draft.ou,
            this.draft.email,
            this.draft.roles
          )
          this.ackInitAssigmentsSaved(result)
        }
      } else {
        this.emailSwitch = false
      }
    }
  },
  methods: {
    ...mapActions('requirements', ['setFieldsAccepted']),
    ...mapActions('profile', [
      'saveDraft',
      'updateValidSection',
      'showOverlay',
      'hideOverlay',
      'ackInitAssigmentsSaved'
    ]),
    clear() {
      this.email = ''
    }
  },
  async created() {
    this.showOverlay()
    this.orgProfile = await getOrgProfile()
    this.email = this.orgProfile.s
    this.initOu = this.orgProfile.o
    this.initRoles = this.orgProfile.r
    this.hideOverlay()
  }
}
</script>

<style lang="scss">
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.67);
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  font-size: 1.4rem;
}
.v-alert__content {
  font-weight: 700;
}
</style>
