<template>
  <v-container class="mt-6">
    <div v-if="!isValidProfileRequest">
      <span
        class="text-h6 text-md-h5 grey--text text--darken-2 font-weight-light"
        >{{ preTitle }}</span
      >
      <h1 class="text-h5 text-md-h3 mb-0 mb-md-9" :class="fontWeightLightMd">
        {{ title }}
      </h1>
    </div>

    <div v-else>
      <v-alert
        rounded="lg"
        type="success"
        class="mt-3"
        color="light-green darken-1"
        text
        v-if="isValidProfileRequest"
      >
        {{ profileCompletedMsg }}
      </v-alert>
      <div
        v-if="isValidProfileRequest"
        class="d-flex justify-center align-center mt-3"
      >
        <v-btn to="Documents" color="identity--text" text>{{
          $t('profile.btnTitle')
        }}</v-btn>
        <v-btn
          to="Documents"
          class="mx-2"
          fab
          dark
          color="identity white--text lg"
        >
          <v-icon dark>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
    </div>

    <employee-profile />

    <v-alert
      rounded="lg"
      type="success"
      class="mt-3"
      color="light-green darken-1"
      text
      v-if="isValidProfileRequest"
    >
      {{ profileCompletedMsg }}
    </v-alert>

    <!-- Saved changes notification -->
    <!-- <v-snackbar v-model="snackbar">
      <template v-slot:action="{ attrs }">
        <v-btn small color="green" v-bind="attrs" @click="logout">
          Salir para que se refljen los cambios
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->

    <v-snackbar rounded="pill" multi-line v-model="snackbar">
      Tus cambios ha sido guardados. Para aplicarlos por favor da salir.
      <template v-slot:action="{ attrs }">
        <v-btn
          class="pl-3"
          x-small
          color="green"
          v-bind="attrs"
          @click="logout"
        >
          Salir
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <div
      v-if="isValidProfileRequest"
      class="d-flex justify-center align-center mt-3"
    >
      <v-btn to="Documents" color="identity--text" text>{{
        $t('profile.btnTitle')
      }}</v-btn>
      <v-btn
        to="Documents"
        class="mx-2"
        fab
        dark
        color="identity white--text lg"
      >
        <v-icon dark>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>

    <v-overlay :value="isVisibleOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import EmployeeProfile from '@/components/people/EmployeeProfile'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Requirements',
  data: () => ({
    snackbar: false,
    newSave: false,
    profileCompletedMsg: '¡Gracias! Tu perfil está completo.',
    profileCompletedSnack:
      '¡Gracias! Tu perfil está completo. Para que los cambios se apliquen, necesitas salir de la aplicación e ingresar de nuevo.'
  }),
  components: {
    EmployeeProfile
  },
  computed: {
    ...mapGetters('profile', [
      'isVisibleOverlay',
      'isValidProfileRequest',
      'areAssignmentsSaved'
    ]),
    fontWeightLightMd() {
      return this.$vuetify.breakpoint.mdAndUp ? 'font-weight-light' : ''
    },
    preTitle() {
      return this.isValidProfileRequest
        ? this.$t('profile.preTitleFulfilled')
        : this.$t('profile.preTitle')
    },
    title() {
      return this.isValidProfileRequest
        ? this.$t('profile.titleFulfilled')
        : this.$t('profile.title')
    }
  },
  watch: {
    isValidProfileRequest(fulfilled) {
      if (fulfilled) {
        this.addSectionCompleted('a')
        this.enableRoute('Documents')
      }
    },
    areAssignmentsSaved(newState) {
      if (newState) {
        this.snackbar = true
      } else {
        this.snackbar = false
      }
    }
  },
  methods: {
    ...mapActions('routes', ['enableRoute']),
    ...mapActions('user', ['addSectionCompleted']),
    closeSnack() {
      this.snackbar = false
    },
    logout() {
      this.closeSnack()
      window.location.assign('/login')
    }
  }
}
</script>
