import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'

export async function initAssignments(pubOid, pubSid, pubRoles) {
  const addEmployee = firebase
    .functions()
    .httpsCallable('users-AddEmployeeIndex')
  const data = { o: pubOid, s: pubSid, r: pubRoles }
  try {
    const response = await addEmployee(data)
    return response.data.success
  } catch (error) {
    return false
  }
}

export async function getOrgProfile() {
  const getOrgProfile = firebase
    .functions()
    .httpsCallable('users-GetEmployeeOrgProfileIndex')
  const data = {}
  const empty = { o: '', s: '', l: '', r: [] }
  try {
    const result = await getOrgProfile(data)
    return result.data.data || empty
  } catch (error) {
    return empty
  }
}
